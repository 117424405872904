import React from 'react';
import { graphql } from 'gatsby';
import { useIntl, Link } from 'gatsby-plugin-intl';

import { Layout, Image } from '../../components';
import useTranslatedPost from '../../utils/useTranslatedPost';
import messages from '../../messages/posts';

const EventPage = ({ data: { wpEvent }, location: { pathname } }) => {
  const intl = useIntl();
  const event = useTranslatedPost(wpEvent, pathname);
  return (
    <Layout fluid>
      <div className="d-flex post-main-container activities-navigation mb-3 container">
        <Link to="/activities">{`< ${intl.formatMessage(messages.back)}`}</Link>
        <div className="mx-3"> - </div>
        <div>{intl.locale === 'en' ? event.dateAgoEn : event.dateAgoFr}</div>
      </div>
      <div className="container">
        <div
          className="post-title mb-5"
          dangerouslySetInnerHTML={{ __html: event.title }}
        />
      </div>
      <Image
        src={
          event.featuredImage?.node?.localFile?.publicURL ||
          event.featuredImage?.node?.sourceUrl
        }
        alt={event.title}
        width="100%"
        height={500}
        className="mb-5"
      />
      <div className="container">
        <div
          dangerouslySetInnerHTML={{ __html: event.content }}
          className="post-body"
        />
      </div>
    </Layout>
  );
};

export default EventPage;
export const query = graphql`
  query($id: String!) {
    wpEvent(id: { eq: $id }) {
      id
      slug
      title
      content
      dateAgoFr: date(fromNow: true, locale: "fr")
      dateAgoEn: date(fromNow: true, locale: "en")
      date(formatString: "D/MM/YYYY")
      excerpt
      tags {
        nodes {
          id
          slug
          name
        }
      }
      categories {
        nodes {
          id
          slug
          name
        }
      }
      author {
        node {
          id
          slug
          name
          avatar {
            url
          }
        }
      }
      language {
        slug
        locale
      }
      translations {
        slug
      }
    }
  }
`;
